import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-333afd1e"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "el-dropdown-link"
};
import { ref } from 'vue';
import { useStore } from 'vuex';
export default {
  __name: 'avatar',

  setup(__props) {
    const store = useStore();
    const squareUrl = ref('https://www.ipolarx.com/api/getLogoIcon');

    const logout = () => {
      store.dispatch('app/logout');
    };

    return (_ctx, _cache) => {
      const _component_el_avatar = _resolveComponent("el-avatar");

      const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");

      const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");

      const _component_el_dropdown = _resolveComponent("el-dropdown");

      return _openBlock(), _createBlock(_component_el_dropdown, null, {
        dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
          default: _withCtx(() => [_createVNode(_component_el_dropdown_item, {
            onClick: logout
          }, {
            default: _withCtx(() => [_createTextVNode("退出")]),
            _: 1
          })]),
          _: 1
        })]),
        default: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_el_avatar, {
          shape: "square",
          size: 40,
          src: squareUrl.value
        }, null, 8, ["src"])])]),
        _: 1
      });
    };
  }

};